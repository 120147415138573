import React from 'react';

import { useLocation, useNavigate } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";

// import logoCyberEssentials from "./../assests/images/logo-cyber-essentials.png";
import logoEcologi from "./../assests/images/logo-ecologi.png";
// import logoNhsct from "./../assests/images/logo-NHSCT.png";

import logoNHSCT from "./../assests/images/logo-NHSCT-md.png"
import logoNHSCTa2x from "./../assests/images/logo-NHSCT-md@2x.png";

import { Link } from "react-router-dom";
import { sendLogoutRequest } from "./../Store/Auth/AuthActions";

const Footer = () => {
      const auth = useSelector(state=>state.auth.auth);
      const dispatch = useDispatch();
      let path = useLocation();
      const navigate =  useNavigate();
      if(path.pathname.includes("leavefeedback") || path.pathname.includes("uptake_request") || path.pathname.includes("uptake_request_by_venue") ||  path.pathname.includes("get_feedback_report")){
            return (<></>)
      }

      const faqHandler = (e)=>{
            e.preventDefault();
            // console.log("faq")
            window.location = "/faq";
      }

      const authHandler = (e)=>{
            e.preventDefault();
            // console.log(data)
            if(auth){
                  const token= localStorage.getItem('nhs_access_token');
                  dispatch(sendLogoutRequest({token:token,data:{},navigate:navigate}));
                  return;
            }
            else {

                  const data = {page:'login'}
                  navigate('/',{
                          state: data
                        });
                  return;
            }
      }
      
      return (
            <footer className="main-footer mt-auto">
                  <div className="container-fluid container__footer-menu">
                        <div className="row">
                              <div className="col-xl-6 mb-5 mb-xl-0 col__footer-text">
                                    <div className="col-left__columns d-flex align-items-start justify-content-between justify-content-md-start">
                                          <div className="col-office-address">
                                                <h6 className="nav-heading fw-bold">Tickets for Medics</h6>
                                                <p>
                                                      Registered Office
                                                      <br/>71-75 Shelton Street
                                                      <br/>Covent Garden
                                                      <br/>London
                                                      <br/>WC2H 9JQ
                                                </p>
                                          </div>
                                          {/* <!-- /.col-office-address --> */}

                                          <div className="col-footer-menus d-flex flex-column flex-lg-row">
                                                <div className="col-footer-menu">
                                                      <h6 className="nav-heading fw-bold">Sections</h6>
                                                      <ul className="nav flex-column">
                                                            <li className="nav-item">
                                                                  <Link className="nav-link p-0" to="/join">Join</Link>
                                                            </li>
                                                            <li className="nav-item">
                                                                  <Link className="nav-link p-0" to="/socialimpact">Social Impact</Link>
                                                            </li>
                                                            <li className="nav-item">
                                                                  <a className="nav-link p-0" href="/" onClick={faqHandler}>FAQ</a>
                                                            </li>
                                                            <li className="nav-item">
                                                                  <Link className="nav-link p-0" to="/contactus">Contact</Link>
                                                            </li>
                                                            <li className="nav-item">
                                                                  {/* <Link className="nav-link p-0" to="/">Login</Link> */}
                                                                  <a className="nav-link p-0" href="/" onClick={authHandler}>
                                                                        {auth ? "Logout" : "Login"}
                                                                  </a>

                                                            </li>
                                                      </ul>
                                                </div>
                                                <div className="col-footer-menu">
                                                      <h6 className="nav-heading fw-bold d-none d-lg-block">Others</h6>
                                                      <ul className="nav flex-column">
                                                            <li className="nav-item">
                                                                  <Link className="nav-link p-0" to="/terms">Terms & Conditions</Link>
                                                            </li>
                                                            <li className="nav-item">
                                                                  <Link className="nav-link p-0" to="/privacy-policy">
                                                                        Privacy Policy
                                                                        {/* Privacy & Cookies */}
                                                                  </Link>
                                                            </li>
                                                      </ul>
                                                </div>
                                          </div>
                                          {/* <!-- /.col-footer-menus --> */}

                                    </div>
                                    {/* <!-- /.row --> */}
                              </div>
                              {/* <!-- /.col-left --> */}

                              <div className="col-xl-6 d-xl-flex col__sponsors">
                                    <ul className="nav ms-xl-auto mt-xl-auto justify-content-between justify-content-lg-start">
                                          {/* <li className="nav-item nav-item__logo__cyber-essentials order-xl-2">
                                                <a className="" href="https://www.ncsc.gov.uk/cyberessentials/overview" target="_blank" rel="noreferrer">
                                                      <img className="logo__cyber-essentials" src={logoCyberEssentials} alt=""/>
                                                </a>
                                          </li> */}
                                          <li className="nav-item nav-item__logo__ecologi order-xl-3">
                                                <a className="" href="https://ecologi.com/centralticketslimited" target="_blank" rel='noreferrer'>
                                                      <img className="logo__ecologi" src={logoEcologi} alt=""/>
                                                </a>
                                          </li>
                                          <li className="nav-item nav-item__logo__NHSCT order-xl-1">
                                                <a className="" href="https://nhscharitiestogether.co.uk" target="_blank" rel='noreferrer'>
                                                      <img className="logo__NHSCT" src={logoNHSCT} srcSet={logoNHSCTa2x} alt=""/>
                                                </a>
                                          </li>
                                    </ul>
                              </div>
                              {/* <!-- /.col-right --> */}

                        </div>
                        {/* <!-- /.row --> */}
                  </div>
                  {/* <!-- /.container__footer-menu --> */}

                  <div className="container-fluid container__copyright">
                        <div className="row">
                              <div className="col">
                                    <div className="d-flex flex-row flex-wrap justify-content-xl-between">
                                          <span className="me-lg-2">
                                                {/* Tickets for Medics is a trading name of Central Tickets Limited, a Company Registered in England (1263393) <span className=" d-lg-none">Copyright © 2021</span> */}
                                                Tickets for Medics is the NHS division of Central Tickets Limited, a Limited Company registered in England (11263393)
                                                {/* Tickets for Medics is a trading name of to Tickets for Medics is the NHS division of Central Tickets Limited */}
                                          </span>
                                          <span className="d-none d-lg-block">Copyright © 2024</span>
                                    </div>
                              </div>
                        </div>
                        {/* <!-- /.row --> */}
                  </div>
                  {/* <!-- /.container__copyright --> */}
            </footer>
      )
}

export default Footer
