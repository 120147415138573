import React,{ useState,useEffect} from "react";

import { useNavigate, useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import stockImage10 from "./../assests/images/stock-image-10.jpg";
import stockImage10a2x from "./../assests/images/stock-image-10@2x.jpg";
// import stockImage10a2x from "./../assests/images/stock-image-10@2x.jpg 2x";

import TomSelect from "tom-select";

import { checkValidityJoin } from "./../Shared/UtilityJoin";
import { submitAltJoinDataRequest } from "./../Store/Auth/AuthActions";
import { UiActions } from "../Store/Ui/Ui";

const JoinAlt = () => {
      
      const [preview,setPreview] = useState('')
      const [state,setState]= useState({

            fname:{value:"",error:false,errorMessage:"Please enter a valid first name"},
            lname:{value:"",error:false,errorMessage:"Please enter a valid last name"},
            password:{value:"",error:false,errorMessage:"Please enter a valid password, a minimum of 6 characters"},
            email:{value:"",error:false,errorMessage:"Please enter a valid email address"},
            emailConfirm:{value:"",error:false,errorMessage:"That’s not a match. Please check the email addresses entered."},
            role:{value:"",error:false,errorMessage:"Please enter your role"},
            city:{value:"",error:false,errorMessage:"Please select your nearest city"},
            mobile:{value:"",error:false,errorMessage:"Please enter a valid UK mobile number"},
            comments:{value:"",error:false,errorMessage:"Please share with us some information regarding your Keyworker status."},
            image:{value:"",error:false,errorMessage:"Please upload documentation that verifies your Keyworker status."},
            checkReceiveAlerts:{value:false,error:false,errorMessage:"Please confirm that you provide your consent to receive a show alerts from Tickets for Medics"},
            checkAgreeTOS:{value:false,error:false,errorMessage:"Please confirm that you agree to the Terms and Conditions of membership"},

      })

      const data = useLocation();
      const altJoinRequest = useSelector(state => state.ui.altJoinRequest)
      const navigate = useNavigate()
      const dispatch = useDispatch()

      useEffect(()=>{

            window.scrollTo({top: 0, behavior: 'smooth'});

            if(data.state){

                  setState(prevState=>{
                        return {
                              ...prevState,
                              fname:{...prevState.fname,value:data.state.fname},
                              lname:{...prevState.lname,value:data.state.lname},
                              password:{...prevState.password,value:data.state.password},
                              email:{...prevState.email,value:data.state.email},
                              role:{...prevState.role,value:data.state.role},
                              city:{...prevState.city,value:data.state.city},
                              mobile:{...prevState.mobile,value:data.state.mobile},
                        }
                  })
            }
            else {
                  navigate("/join")
            }

      },[])

      useEffect(() => {
            
            if (!state.image.value) {
                setPreview("")
                return
            }
    
            const objectUrl = URL.createObjectURL(state.image.value)
            setPreview(objectUrl)
    
            // free memory when ever this component is unmounted
            return () => URL.revokeObjectURL(objectUrl)
      }, [state.image.value])

      useEffect(()=>{

            window.scrollTo({top: 0, behavior: 'smooth'});
            const city = new TomSelect('#joinInputNearestCity',{
                  plugins: ['remove_button'],
                  create: false,
                  maxOptions:150,
                  sortField: {
                        field: "text"
                  },
                  items:[data.state && data.state.city ],
                  openOnFocus:false,
                  onType:(str)=>{
                        if(str.length > 1){
                             document.querySelector("#joinInputNearestCity + .ts-wrapper .ts-dropdown").style.display = "block"
                        }
                        else{
                              document.querySelector("#joinInputNearestCity + .ts-wrapper .ts-dropdown").style.display = "none"
                        }
                  },	
                  render:{
                        no_results:function(data,escape){
                              document.querySelector("#joinInputNearestCity + .ts-wrapper .ts-dropdown").style.border = "0px"
                        }
                  }
            });
      
            return ()=>{
                  city.clear();
            }
      },[])

      const changeHandler = (event)=>{
            
            const name = event.target.name;
            let value = event.target.value;
            let radioButton = false;

            if(name === "fname" || name === "lname"){
                  value = capitalizeFirstLetter(value);
            }

            if(event.target.name === "image"){
                  value = event.target.files[0];
            }

            if(name === "checkReceiveAlerts" || name==="checkAgreeTOS"){
                  value = event.target.checked;
                  radioButton = true;
            }

            if(name === "mobile"){
                  value = event.target.value.replace(/\D/g, '');
            }

            setState(prevState=>{
                  return {
                        ...prevState,
                        [name]:{...prevState[name],value:value}
                  }
            })

            if(radioButton){
                  onfocusHandler();
            }
      }

      const checkForNhsEmailHandler = ()=>{
            const value = state.email.value;
            const errorCheck = checkValidityJoin(value,"email");
            if(errorCheck.isValid){

                  if((value.indexOf("@nhs.net") !== -1) || (value.indexOf("nhs.uk") !== -1) || (value.indexOf("nhs.scot") !== -1)){
                        const data = {
                              fname:state.fname.value,
                              lname:state.lname.value,
                              password:state.password.value,
                              mobile:state.mobile.value,
                              nhsEmail:value,
                              role:state.role.value,
                              city:state.city.value
                        }

                        joinNavigateHandler(data);
                        return;
                  }
            }

      }

      const joinNavigateHandler = (data)=>{

            navigate('/join',{
                  state: data
            })
      }

      const capitalizeFirstLetter = (string) => {
            return string.charAt(0).toUpperCase() + string.slice(1);
      }

      const onfocusHandler = ()=>{

            setState(prevState=>{

                  return {
                        ...prevState,
                        fname:{...prevState.fname,error:false},
                        lname:{...prevState.lname,error:false},
                        password:{...prevState.password,error:false},
                        email:{...prevState.email,error:false},
                        emailConfirm:{...prevState.emailConfirm,error:false},
                        role:{...prevState.role,error:false},
                        city:{...prevState.city,error:false},
                        mobile:{...prevState.mobile,error:false},
                        comments:{...prevState.comments,error:false},
                        image:{...prevState.image,error:false},
                        checkReceiveAlerts:{...prevState.checkReceiveAlerts,error:false},
                        checkAgreeTOS:{...prevState.checkAgreeTOS,error:false},
                  }
                 
            })
      }

      const backHandler = (event)=>{
            event.preventDefault();
            navigate(-1)
      }

      const submitJoinDataHandler = (event)=>{

            event.preventDefault();
            const errorStore = [];
            const copy = {

            }

            for(let key in state)
            {
                  const errorCheck = checkValidityJoin(state[key].value,key,state.email.value);
                              
                  if(!errorCheck.isValid)
                  {
                        errorStore.push(key);
                  }       
                  copy[key] = {...state[key],error:!errorCheck.isValid,errorMessage:errorCheck.validationMessage}
                        
            }
           

            if(errorStore.length > 0)
            {
                  setState(prevState=>{
                        return {
                              ...prevState,
                              ...copy
                        }
                  })
            }
            else
            {
                  // const data = {
                  //       fname:state.fname.value,
                  //       lname:state.lname.value,
                  //       password:state.password.value,
                  //       work_email:state.email.value,
                  //       role:state.role.value,
                  //       city:state.city.value,
                  //       // organisation:state.organisation.value,
                  //       comments:state.comments.value,
                  //       image:state.image.value,
                  //       agree_tos:state.checkAgreeTOS.value,
                  //       receive_alerts:state.checkReceiveAlerts.value
                  // }

                  const ragistration_special = localStorage.getItem('nhs_ragistration_type');
                  const ragistration_type = (ragistration_special !== "undefined" && ragistration_special) ? ragistration_special  : "";

                  let formData = new FormData();
                  formData.append('image',state.image.value,state.image.value.name);
                  formData.append('fname',state.fname.value);
                  formData.append('lname',state.lname.value);
                  formData.append('password',state.password.value);
                  formData.append('work_email',state.email.value);
                  formData.append('role',state.role.value);
                  formData.append('city',state.city.value);
                  formData.append('mobile_number',state.mobile.value);
                  // formData.append('organization',state.organisation.value);
                  formData.append('comments',state.comments.value);
                  formData.append('agree_tos',state.checkAgreeTOS.value);
                  formData.append('receive_alerts',state.checkReceiveAlerts.value);
                  formData.append('nhs_ajtix',localStorage.getItem('nhs_ajtix') || false);
                  formData.append('ragistration_type',ragistration_type);
                  // for (var pair of formData.entries()) {
                  //       console.log(pair[0]+ ', ' + pair[1]); 
                  // }
                  // return;

                  dispatch(UiActions.setAltJoinRequest({request:true}))
                  dispatch(submitAltJoinDataRequest({data:formData,navigate:navigate}))
            }
                
      }
          
      return (

            <main>
                  <section className="section__join section__join-alt">
                        {/* <!--
                              NOTE: class "container__block" removes child row paddings and negative margins; and col-* paddings
                        --> */}
                        <div className="container-fluid container__block">
                              <div className="row">
                                    <div className="col-md-6 col__left order-md-2">
                                          <div className="content content__form">
                                                <div className="content__body">
                                                      <h1 className="fw-bold">
                                                            {/* Join for free! */}
                                                            Keyworker Verification
                                                      </h1>
            
                                                      <form onSubmit={submitJoinDataHandler} autoComplete="off">
                                                            <input autoComplete="false" name="hidden" type="text" style={{"display":"none"}}/>
                                                            <div className="row">
                                                                  <div className="col-lg-6">
                                                                        <div className="form-group">
                                                                              <label htmlFor="joinInputFirstName" className="form-label fw-normal">First Name</label>
                                                                              <input type="text" className="form-control form-control-xl-lg" id="joinInputFirstName" name="fname" value={state.fname.value} onChange={changeHandler} onFocus={onfocusHandler} disabled={altJoinRequest}/>
                                                                              <div className="invalid-feedback" style={{"visibility": state.fname.error && "visible"}}>{state.fname.errorMessage}</div>
                                                                        </div>
                                                                        {/* <!-- /.form-group --> */}
                                                                  </div>
                                                                  <div className="col-lg-6">
                                                                        <div className="form-group">
                                                                              <label htmlFor="joinInputLastName" className="form-label fw-normal">Last Name</label>
                                                                              <input type="text" className="form-control form-control-xl-lg" id="joinInputLastName" name="lname" value={state.lname.value} onChange={changeHandler} onFocus={onfocusHandler} disabled={altJoinRequest}/>
                                                                              <div className="invalid-feedback" style={{"visibility": state.lname.error && "visible"}}>{state.lname.errorMessage}</div>
                                                                        </div>
                                                                        {/* <!-- /.form-group --> */}
                                                                  </div>
                                                            </div>
                                                            {/* <!-- /.row --> */}
                                                            <div className="row">
                                                                  <div className="col-md-12">
                                                                        <div className="form-group">
                                                                              <label htmlFor="joinInputPassword" className="form-label fw-normal">Password</label>
                                                                              <input type="password" className="form-control form-control-xl-lg" id="joinInputPassword" name="password" value={state.password.value} onChange={changeHandler} onFocus={onfocusHandler} disabled={altJoinRequest}  autoComplete="new-password"/>
                                                                              <div className="invalid-feedback" style={{"visibility": state.password.error && "visible"}}>{state.password.errorMessage}</div>
                                                                        </div>
                                                                        {/* <!-- /.form-group --> */}
                                                                  </div>
                                                            </div>

                                                            {/* <!-- /.row --> */}
                                                            <div className="row">
                                                                  <div className="col-md-12">
                                                                        <div className="form-group">
                                                                              <label htmlFor="joinInputWorkEmail" className="form-label fw-normal">Key Worker Email</label>
                                                                              <input type="email" className="form-control form-control-xl-lg" id="joinInputWorkEmail" name="email" value={state.email.value} onChange={changeHandler} onFocus={onfocusHandler} onBlur={checkForNhsEmailHandler} disabled={altJoinRequest} autoComplete="new-password"/>
                                                                              <div className="invalid-feedback" style={{"visibility": state.email.error && "visible"}}>{state.email.errorMessage}</div>      
                                                                        </div>
                                                                        {/* <!-- /.form-group --> */}
                                                                  </div>
                                                            </div>

                                                             {/* <!-- /.row --> */}
                                                             <div className="row">
                                                                  <div className="col-md-12">
                                                                        <div className="form-group">
                                                                              <label htmlFor="joinInputWorkEmailConfirm" className="form-label fw-normal">Key Worker Email Confirmation</label>
                                                                              <input type="email" className="form-control form-control-xl-lg" id="joinInputWorkEmailConfirm" name="emailConfirm" value={state.emailConfirm.value} onChange={changeHandler} onFocus={onfocusHandler} disabled={altJoinRequest}/>
                                                                              <div className="invalid-feedback" style={{"visibility": state.emailConfirm.error && "visible"}}>{state.emailConfirm.errorMessage}</div>    
                                                                        </div>
                                                                        {/* <!-- /.form-group --> */}
                                                                  </div>
                                                            </div>

                                                            {/* <!-- /.row --> */}
                                                            <div className="row">
                                                                  <div className="col-lg-6">
                                                                        <div className="form-group">
                                                                              <label htmlFor="joinInputRole" className="form-label fw-normal">Role</label>
                                                                              <input type="text" className="form-control form-control-xl-lg" id="joinInputRole" value={state.role.value} name="role" onChange={changeHandler} onFocus={onfocusHandler} disabled={altJoinRequest}/>
                                                                              <div className="invalid-feedback" style={{"visibility": state.role.error && "visible"}}>{state.role.errorMessage}</div>
                                                                        </div>
                                                                        {/* <!-- /.form-group --> */}
                                                                  </div>
                                                                  <div className="col-lg-6">
                                                                        <div className="form-group" onFocus={onfocusHandler}>
                                                                              <label htmlFor="joinInputNearestCity" className="form-label fw-normal">Nearest City</label>
                                                                              <select className="form-control tomselect tomselect-xl-lg" id="joinInputNearestCity" aria-label="Nearest City" autoComplete="off" name="city" value={state.city.value} onChange={changeHandler}  disabled={altJoinRequest} placeholder="Enter your nearest City">
                                                                              {/* <option value="0" hidden>Select City</option> */}
                                                                                    <option value="1">Aberdeen</option>
                                                                                    <option value="2">Armagh</option>
                                                                                    <option value="3">Bangor</option>
                                                                                    <option value="4">Barnsley</option>
                                                                                    <option value="5">Basildon</option>
                                                                                    <option value="6">Basingstoke</option>
                                                                                    <option value="7">Bath</option>
                                                                                    <option value="8">Bedford</option>
                                                                                    <option value="9">Belfast</option>
                                                                                    <option value="10">Birkenhead</option>
                                                                                    <option value="11">Birmingham</option>
                                                                                    <option value="12">Blackburn</option>
                                                                                    <option value="13">Blackpool</option>
                                                                                    <option value="14">Bolton</option>
                                                                                    <option value="15">Bournemouth</option>
                                                                                    <option value="16">Bradford</option>
                                                                                    <option value="17">Brighton</option>
                                                                                    <option value="18">Brighton and Hove</option>
                                                                                    <option value="19">Bristol</option>
                                                                                    <option value="20">Cambridge</option>
                                                                                    <option value="21">Canterbury</option>
                                                                                    <option value="22">Cardiff</option>
                                                                                    <option value="23">Carlisle</option>
                                                                                    <option value="24">Chelmsford</option>
                                                                                    <option value="25">Cheltenham</option>
                                                                                    <option value="26">Chester</option>
                                                                                    <option value="27">Chesterfield</option>
                                                                                    <option value="28">Chichester</option>
                                                                                    <option value="29">Colchester</option>
                                                                                    <option value="30">Coventry</option>
                                                                                    <option value="31">Crawley</option>
                                                                                    <option value="32">Darlington</option>
                                                                                    <option value="33">Derby</option>
                                                                                    <option value="34">Doncaster</option>
                                                                                    <option value="35">Dundee</option>
                                                                                    <option value="36">Durham</option>
                                                                                    <option value="37">Eastbourne</option>
                                                                                    <option value="38">Edinburgh</option>
                                                                                    <option value="39">Ely</option>
                                                                                    <option value="40">Exeter</option>
                                                                                    <option value="41">Gateshead</option>
                                                                                    <option value="42">Gillingham</option>
                                                                                    <option value="43">Glasgow</option>
                                                                                    <option value="44">Gloucester</option>
                                                                                    <option value="45">Grimsby</option>
                                                                                    <option value="46">Halifax</option>
                                                                                    <option value="47">Hartlepool</option>
                                                                                    <option value="48">Hastings</option>
                                                                                    <option value="49">Hemel Hempstead</option>
                                                                                    <option value="50">Hereford</option>
                                                                                    <option value="51">High Wycombe</option>
                                                                                    <option value="52">Huddersfield</option>
                                                                                    <option value="53">Inverness</option>
                                                                                    <option value="54">Ipswich</option>
                                                                                    <option value="55">Kingston upon Hull</option>
                                                                                    <option value="56">Lancaster</option>
                                                                                    <option value="57">Leeds</option>
                                                                                    <option value="58">Leicester</option>
                                                                                    <option value="59">Lichfield</option>
                                                                                    <option value="60">Lincoln</option>
                                                                                    <option value="61">Lisburn</option>
                                                                                    <option value="62">Liverpool</option>
                                                                                    <option value="63">London</option>
                                                                                    <option value="64">Londonderry</option>
                                                                                    <option value="65">Luton</option>
                                                                                    <option value="66">Maidstone</option>
                                                                                    <option value="67">Manchester</option>
                                                                                    <option value="68">Middlesbrough</option>
                                                                                    <option value="69">Milton Keynes</option>
                                                                                    <option value="70">Newcastle upon Tyne</option>
                                                                                    <option value="71">Newport</option>
                                                                                    <option value="72">Newry</option>
                                                                                    <option value="73">Northampton</option>
                                                                                    <option value="74">Norwich</option>
                                                                                    <option value="75">Nottingham</option>
                                                                                    <option value="76">Nuneaton</option>
                                                                                    <option value="77">Oldham</option>
                                                                                    <option value="78">Oxford</option>
                                                                                    <option value="79">Peterborough</option>
                                                                                    <option value="80">Plymouth</option>
                                                                                    <option value="81">Poole</option>
                                                                                    <option value="82">Portsmouth</option>
                                                                                    <option value="83">Preston</option>
                                                                                    <option value="84">Raleigh</option>
                                                                                    <option value="85">Reading</option>
                                                                                    <option value="86">Ripon</option>
                                                                                    <option value="87">Rochdale</option>
                                                                                    <option value="88">Rotherham</option>
                                                                                    <option value="89">Sale</option>
                                                                                    <option value="90">Salford</option>
                                                                                    <option value="91">Salisbury</option>
                                                                                    <option value="92">Sheffield</option>
                                                                                    <option value="93">Slough</option>
                                                                                    <option value="94">Solihull</option>
                                                                                    <option value="95">Southampton</option>
                                                                                    <option value="96">Southend-on-Sea</option>
                                                                                    <option value="97">Southport</option>
                                                                                    <option value="98">St Albans</option>
                                                                                    <option value="99">St Davids</option>
                                                                                    <option value="100">St Helens</option>
                                                                                    <option value="101">Stevenage</option>
                                                                                    <option value="102">Stirling</option>
                                                                                    <option value="103">Stockport</option>
                                                                                    <option value="104">Stoke-on-Trent</option>
                                                                                    <option value="105">Sunderland</option>
                                                                                    <option value="106">Sutton Coldfield</option>
                                                                                    <option value="107">Swansea</option>
                                                                                    <option value="108">Swindon</option>
                                                                                    <option value="109">Telford</option>
                                                                                    <option value="110">Truro</option>
                                                                                    <option value="111">Wakefield</option>
                                                                                    <option value="112">Warrington</option>
                                                                                    <option value="113">Watford</option>
                                                                                    <option value="114">Wells</option>
                                                                                    <option value="115">Westminster</option>
                                                                                    <option value="116">Weston-super-Mare</option>
                                                                                    <option value="117">Wigan</option>
                                                                                    <option value="118">Winchester</option>
                                                                                    <option value="119">Woking</option>
                                                                                    <option value="120">Wolverhampton</option>
                                                                                    <option value="121">Worcester</option>
                                                                                    <option value="122">Worthing</option>
                                                                                    <option value="123">York</option>
                                                                              </select>
                                                                              <div className="invalid-feedback" style={{"visibility": state.city.error && "visible"}}>{state.city.errorMessage}</div>
                                                                        </div>
                                                                        {/* <!-- /.form-group --> */}
                                                                  </div>
                                                            </div>
                                                            {/* <!-- /.row --> */}

                                                            <div className="row">
                                                                  <div className="col-md-12">
                                                                        <div className="form-group">
                                                                              <label htmlFor="joinInputMobile" className="form-label fw-normal">Mobile Number</label>
                                                                              <input type="text" className="form-control form-control-xl-lg" id="joinInputMobile" name="mobile" value={state.mobile.value} onChange={changeHandler} onFocus={onfocusHandler} disabled={altJoinRequest}/>
                                                                              <div className="invalid-feedback" style={{"visibility": state.mobile.error && "visible"}}>{state.mobile.errorMessage}</div>
                                                                        </div>
                                                                        {/* <!-- /.form-group --> */}
                                                                  </div>
                                                            </div>
                                                            {/* <!-- /.row --> */}
                                                
                                                            <div className="row">
                                                                  <div className="col-xxl-7 order-xxl-2">
                                                                        <div className="form-group">
                                                                              <label htmlFor="joinInputComments" className="form-label fw-normal">Comments</label>
                                                                              <textarea className="form-control form-control-xl-lg" id="joinInputComments" name="comments" value={state.comments.value} onChange={changeHandler} onFocus={onfocusHandler} disabled={altJoinRequest}></textarea>
                                                                              <div className="invalid-feedback" style={{"visibility": state.comments.error && "visible"}}>{state.comments.errorMessage}</div>
                                                                        </div>
                                                                        {/* <!-- /.form-group --> */}
                                                                  </div>
                                                                  <div className="col-xxl-5 order-xxl-1">
                                                                        <div className="form-group form-group__file">
                                                                              <label className="form-label">Work ID Card*</label>
                                                                              <div className="d-flex flex-row flex-xxl-column">
                                                                                    <div className="form-file-wrapper">
                                                                                          <div className="custom-form-file">
                                                                                                <button className="btn btn__custom-file-upload" disabled={altJoinRequest}>Choose Image</button>
                                                                                                <input type="file" name="image" accept="image/*" onChange={changeHandler} onFocus={onfocusHandler}  disabled={altJoinRequest}/>
                                                                                                {state.image.value && <img src={preview} style={{"width":"141px","height":"80px","borderRadius":"10px","marginTop":"10px"}} alt=""/>}
                                                                                                {!state.image.value &&<div src="" style={{"width":"141px","height":"80px","borderRadius":"10px","marginTop":"10px","background":"#d5d5d5"}} alt=""></div>}
                                                                                          </div>
                                                                                    </div>
                                                                                    <div>
                                                                                          <p className="p-asterisk mb-0">
                                                                                                *Please upload your work ID card or something that indicates that you work with, or provide services to the NHS.
                                                                                          </p>
                                                                                    </div>
                                                                              </div>
                                                                              <div className="invalid-feedback" style={{"visibility": state.image.error && "visible"}}>
                                                                                    {state.image.errorMessage}
                                                                              </div>
                                                                        </div>
                                                                        {/* <!-- /.form-group --> */}
                                                                  </div>
                                                            </div>
                                                            {/* <!-- /.row --> */}

                                                            <div className="row">
                                                                  <div className="col-md-12">
                                                                        <div className="form-group form-group__custom-controls">
                                                                              <div className="custom-control custom-checkbox custom-checkbox__radio">
                                                                                    <input type="checkbox" className="custom-control-input" id="checkReceiveAlerts" name="checkReceiveAlerts" checked={state.checkReceiveAlerts.value} value={state.checkReceiveAlerts.value} onChange={changeHandler} disabled={altJoinRequest}/>
                                                                                    <label className="custom-control-label">
                                                                                          I provide my consent to receive show alerts from Tickets for Medics.
                                                                                    </label>
                                                                                    <div className="invalid-feedback" style={{"visibility": state.checkReceiveAlerts.error && "visible"}}>{state.checkReceiveAlerts.errorMessage}</div>
                                                                              </div>
                                                                              <div className="custom-control custom-checkbox custom-checkbox__radio">
                                                                                    <input type="checkbox" className="custom-control-input" id="checkAgreeTOS" name="checkAgreeTOS" checked={state.checkAgreeTOS.value} value={state.checkAgreeTOS.value} onChange={changeHandler} disabled={altJoinRequest}/>
                                                                                    <label className="custom-control-label" >
                                                                                          I confirm that I agree to the <Link className="text-decoration-none" to="/terms" target="_blank">Terms & Conditions</Link> & <Link className="text-decoration-none" to="/privacy-policy" target="_blank">Privacy Policy</Link> of membership and that I either work for the NHS, or for a company that provides services to the NHS.
                                                                                    </label>
                                                                                    <div className="invalid-feedback" style={{"visibility": state.checkAgreeTOS.error && "visible"}}>{state.checkAgreeTOS.errorMessage}</div>
                                                                              </div>
                                                                        </div>
                                                                        {/* <!-- /.form-group --> */}
                                                                  </div>
                                                            </div>
                                                            {/* <!-- /.row --> */}

                                                            <div className="row">
                                                                  <div className="col-md-12">
                                                                        <div className="form-group form-group__buttons d-flex align-items-center flex-column flex-sm-row justify-content-sm-between flex-md-column flex-lg-row justify-content-lg-between">
                                                                              <button type="submit" className="btn btn-primary fw-bold w-xl-50" onClick={submitJoinDataHandler} disabled={altJoinRequest}>Create Account</button>
                                                                              <a href="/" className="btn btn__back d-flex align-items-center px-3 ps-lg-2 pe-lg-0" onClick={backHandler}>Back</a>
                                                                        </div>
                                                                        {/* <!-- /.form-group --> */}
                                                                  </div>
                                                            </div>
                                                            {/* <!-- /.row --> */}
                                                      </form>
            
                                                </div>
                                                {/* <!-- /.content__body --> */}
                                          </div>
                                          {/* <!-- /.content --> */}
                                    </div>
                                    {/* <!-- .col-md-6 --> */}

                                    <div className="col-md-6 col__right order-md-1 border-right-sm border-top border-top-md-0">
                                          <div className="content h-100">
                                                <div className="content__body content__body-image h-100">
                                                      <img className="img-hero img-fluid" srcSet={stockImage10a2x} src={stockImage10} alt=""/>
                                                </div>
                                                {/* <!-- /.content__body --> */}

                                          </div>
                                          {/* <!-- /.content --> */}

                                    </div>
                                    {/* <!-- .col-sm-6 --> */}

                              </div>
                              {/* <!-- /.row --> */}

                        </div>
                        {/* <!-- /.container-fluid  --> */}

                  </section>
                  {/* <!-- /.section__ --> */}
            </main>

      )
}

export default JoinAlt;
