/* eslint-disable */
import React, {useEffect} from 'react';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "./assests/css/stylesheet.css";
import './App.css';

import {  Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";

import MainLayout from './Layouts/MainLayout';
import Home from './components/Home';
import CurrentListing from './components/CurrentListing/CurrentListing';
import EventDetail from './components/EventDetails/EventDetail';
import BookingHistory from "./components/Bookings/BookingHistory/BookingHistory";
import Settings from "./components/Settings/Settings";
import LeaveFeedBack from "./components/LeaveFeedBack/LeaveFeedBack"
import ResetPassword from "./components/ResetPassword";
import AccessNeeds from "./components/AccessNeeds/AccessNeeds";
import ReactiveAccount from './components/ReactiveAccount/ReactiveAccount';
import ReactiveForm from "./components/ReactiveForm/ReactiveForm";
import Uptake from "./components/Uptake/Uptake";
import FeedbackReport from './components/FeedbackReport/FeedbackReport';
import Join from "./components/Join";
import JoinAlt from "./components/JoinAlt";
import SocialImpact from "./components/SocialImpact";
import Faq from "./components/Faq";
import PrivacyPolicy from './components/PrivacyPolicy';
import Terms from './components/Terms';
import ContactUs from './components/ContactUs/ContactUs';
import Footer from './Layouts/Footer';

import Modal from './Ui/Modal/Modal';
import OtpVerificationModal from './Ui/OtpVerificationModal/OtpVerificationModal';
import SelectCityNHSTrust from "./Ui/SelectCityAndNHSTrust/SelectCityNHSTrust";
import CookiesConsentPage from './components/Cookies/Cookies';
import { AuthActions } from "./Store/Auth/Auth";
import { UiActions } from "./Store/Ui/Ui";
import { verifyRegistration, userVerification } from "./Store/Auth/AuthActions";

import StartFirebase from "./config/firebase";
import { ref, onValue } from "firebase/database";


const db = StartFirebase();

function App() {

      const auth                    =      useSelector(state=>state.auth);
      const ui                      =      useSelector(state=>state.ui);
      const token       =      localStorage.getItem("nhs_access_token");
      const dispatch    =      useDispatch();
      let path          =      useLocation();
      const navigate    =      useNavigate();
      const firebaseKey = localStorage.getItem('nhs_firebase_key');
      const nhs_is_change_password = localStorage.getItem("nhs_is_change_password") || false

      useEffect(()=>{
            if(!path.pathname.includes("/settings") && nhs_is_change_password == 'true') {
                  navigate("/settings")
            }

      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[path.pathname])

      useEffect(() => {
            
            if(path.pathname.includes("keyworker_login")){
                  
                  if(auth.auth){
                        dispatch(AuthActions.logout());
                  }
                  dispatch(UiActions.setAlert({model:true,message:'We have already confirmed your Key Worker status.&nbsp; You can now login to Tickets for Medics, using your Central Tickets login.'}))
                  return;
            }

            if(path.pathname.includes("user_verification")){
                  
                  const verificationId = path.pathname.split("/")[2];
                  dispatch(userVerification({id:verificationId}))
                  return;
            }

            if(path.pathname.includes("payment") &&path.pathname.includes("failed")){
                  dispatch(UiActions.setAlert({model:true,message:'Your card is failed to verify 3d secure transaction'}))
            }
            if(path.pathname.includes("verification") || path.pathname.includes("tfm_verification") ){

                  let is_tfm= false;
                  if(path.pathname.includes("tfm_verification")){
                        is_tfm= true;
                  }
                  const verificationToken = path.pathname.split("/")[2];

                  dispatch(verifyRegistration({token:verificationToken,is_tfm:is_tfm,navigate:navigate}))
            }

            if(path.pathname.includes("eventdetails") || path.pathname.includes("accessneeds")){
                  
                  if(!token){

                        localStorage.setItem('nhs_redirect',path.pathname)
                  }
            }
           
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [path.pathname,navigate])

      useEffect(()=>{

            const token = localStorage.getItem("nhs_access_token");
            const name  = localStorage.getItem("nhs_access_name");
            const mail  = localStorage.getItem("nhs_access_email");
            const mobilenumber = localStorage.getItem("nhs_access_mobilenumber");
            const is_verified  = localStorage.getItem("nhs_is_verified");
            const firebase_key  = localStorage.getItem("nhs_firebase_key");
            const reactive  = localStorage.getItem("nhs_reactive") || "0";
            const vendor  = localStorage.getItem("nhs_vendor") || "";

            const nhs_city  = localStorage.getItem("nhs_city") || "";
            const nhs_role  = localStorage.getItem("nhs_role") || "";
            const nhs_trust  = localStorage.getItem("nhs_trust") || "";
            const nhs_is_change_password = localStorage.getItem('nhs_is_change_password') || false
            const nhs_cookies_local = localStorage.getItem('nhs_cookies')
            let nhs_cookies;
            if(typeof nhs_cookies_local !== undefined && nhs_cookies_local !== null){
                  nhs_cookies =  JSON.parse(localStorage.getItem('nhs_cookies'))
            } else {
                  nhs_cookies = {
                        essential : false,
                        advertising: false
                  }
            }
            // console.log(!token && !auth.nhs_access_token,"testing commit---")
                
            if(path.pathname.includes("join/ajtix")){
                  localStorage.setItem('nhs_ajtix',true)
            }
            if(token && !auth.nhs_access_token)
            {
                  dispatch(AuthActions.checkLogin({
                        token:token,
                        name:name,
                        email:mail,
                        mobilenumber:mobilenumber,
                        is_verified:is_verified,
                        firebase_key:firebase_key,
                        reactive:reactive,
                        vendor:vendor,
                        nhs_city:nhs_city,
                        nhs_role:nhs_role,
                        nhs_trust:nhs_trust,
                        nhs_is_change_password : nhs_is_change_password
                  }));
                              
                  // if(path.pathname.length <= 1){
                  //       return navigate("/currentListing");
                  // }
            }
            else if(!token && !auth.nhs_access_token && path.pathname.length > 1){
                  // return navigate("/");
            }

            if(nhs_cookies && (nhs_cookies.essential != auth.nhs_cookies.essential || auth.nhs_cookies.advertising != nhs_cookies.advertising)){
                  console.log(nhs_cookies, 'nhs cookies')
                  dispatch(AuthActions.setNHSCookies(nhs_cookies))
            }

            // if(auth.auth && !token){
                  // console.log(auth.auth,!token)
                  // dispatch(AuthActions.logout());
            // }

      })


      useEffect(()=>{
            
            if(firebaseKey) {

                  const dbRef = ref(db,'/contact/'+firebaseKey)
                  onValue(dbRef, (snapshot) => {
                        const data = snapshot.val();
                        let todoItems = {...data};
                        // console.log("todo item register", JSON.stringify(todoItems))

                        if (todoItems.block_status === 1 || todoItems.block_status === 2 || todoItems.block_status === 3) {          
                              dispatch(AuthActions.logout())
                              dispatch(UiActions.setAlert({model:true,message:"A pause has been applied to your account. &nbsp;We have sent you an email with further details."}))
                              localStorage.setItem("pauseAccount",true)
                        }   
                  });
            }

      },[firebaseKey])


      useEffect(()=>{
            if(auth?.nhs_cookies?.advertising) {
                  loadGoogleAnalytics()
            }
      },[auth.nhs_cookies])

      const loadGoogleAnalytics = () => {
            const GoogleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID
            const script = document.createElement('script');
            script.src = `https://www.googletagmanager.com/gtag/js?id=${GoogleAnalyticsId}`;
            script.async = true;
            document.head.appendChild(script);
            
            script.onload = () => {
                  window.dataLayer = window.dataLayer || [];
                  function gtag() {
                        window.dataLayer.push(arguments);
                  }
                  gtag('js', new Date());
                  gtag('config', `${GoogleAnalyticsId}`, {
                        anonymize_ip: true, // Ensure IP anonymization
                  });
            };
      };

      const closeBackdropHandler = ()=> {

            dispatch(UiActions.setAlert({model:false,message:''}))
      }

      const confirmMessageHandler = ()=>{

            dispatch(UiActions.confirmMessage({type:{...ui.alert.type,value:"confirm",status:true}}))
      }
      
      if(auth.nhs_access_token && token && auth.nhs_reactive === "1") {
            const type = path.pathname.replace("/","");
           
            if(type !== "Home/reactive"){
                  return <Navigate to="/Home/reactive" />;
            }
            
      }
      
      return (
            
            <React.Fragment>
                  {(auth.nhs_cookies && !auth.nhs_cookies?.essential && !auth.nhs_cookies?.advertising)&& <CookiesConsentPage />}
                  {ui.alert.model && <Modal show={ui.alert.model} type={ui.alert.type} closeBackdrop={closeBackdropHandler} confirmMessage={confirmMessageHandler}>{ui.alert.message}</Modal>}
                  {(auth.nhs_is_verified === "false" && auth.auth && ((auth.nhs_city !== "0" && auth.nhs_city) && (auth.nhs_role !== "0" && auth.nhs_role))) && <OtpVerificationModal show={auth.nhs_is_verified === "false"}/>}
                  {(auth.auth && ((auth.nhs_city !== "0" && !auth.nhs_city) || (auth.nhs_role !== "0" && !auth.nhs_role))) && <SelectCityNHSTrust show={true}/>}
                  <Routes>
                        {/* <Route path=":eventDetail" element={<h1> Dynmaic Routes</h1>}/> */}
                        <Route path="/" element={<MainLayout/>}>
                              {/* <Route path="/currentListing" element={<CurrentListing/> }/> */}
                              <Route path="/currentListing" element={(!token && !auth.auth) ? <Navigate to="/" replace/> : <CurrentListing/> }/>
                              <Route path="/eventdetails" element={(!token && !auth.auth) ? <Navigate to="/" replace/> : <EventDetail/> }>
                                    <Route path="/eventdetails/:eventId" element={<></>}/>
                                    {/* <Route index element={<h1>Event detail index</h1>}/> */}
                              </Route>
                              <Route path="/booking-history/:bookingId" element={(!token && !auth.auth)  ? <Navigate to="/" replace/> : <BookingHistory/> }/>
                              <Route path="/accessneeds" element={(!token && !auth.auth)  ? <Navigate to="/" replace/> : <AccessNeeds/> }/>
                              <Route path="/booking-history" element={(!token && !auth.auth)  ? <Navigate to="/" replace/> : <BookingHistory/> }/>
                              <Route path="/settings" element={(!token && !auth.auth) ? <Navigate to="/" replace/> : <Settings/> }/>
                              <Route path="/change_password/:token" element={<ResetPassword/>} />
                              <Route path="/reactive_account/:id" element={<ReactiveAccount/>} />
                              <Route path="/reactive_account_response/:status" element={<ReactiveAccount/>} />
                              <Route path="/Home/reactive" element={<ReactiveForm/>} />
                              <Route path="/join" element={(!token && !auth.auth) ? <Join/> : <Navigate to="/" replace/>}/>
                              <Route path="/join/:param" element={(!token && !auth.auth) ? <Join/> : <Navigate to="/" replace/>}/>
                              <Route path="/join/:ajtix" element={(!token && !auth.auth) ? <Join/> : <Navigate to="/" replace/>}/>
                              <Route path="/join-alt" element={(!token && !auth.auth) ?<JoinAlt/> : <Navigate to="/" replace/>}/>
                              <Route path="/socialimpact" element={<SocialImpact/>}/>
                              <Route path="/faq" element={<Faq/>}/>
                              <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                              <Route path="/terms" element={<Terms/>}/>
                              <Route path="/contactus" element={<ContactUs/>}/>
                              <Route path="/payment/failed" element={(!token && !auth.auth) ? <Home/> : <Navigate to="/currentListing" replace/>}/>
                              <Route path="/keyworker_login" element={<Home/>}/>
                              <Route path="/verification/:id" element={(!token && !auth.auth) ? <Home/> : <Navigate to="/currentListing" replace/>}/>
                              <Route path="/tfm_verification/:id" element={(!token && !auth.auth) ? <Home/> : <Navigate to="/currentListing" replace/>}/>
                              {/* <Route index element={!token ? <Home/> : <Navigate to="/currentListing" replace/>}/> */}
                              {/* <Route index element={(!token && !auth.auth) ? <Home/> : <Navigate to="/currentListing" replace/>}/> */}
                              <Route index element={<Home/>}/>
                        </Route>
                        <Route path="*" element={<MainLayout/>}>
                              <Route path="*" element={<Home/>}/>
                        </Route>
                        <Route path="/leavefeedback/:id" element={<LeaveFeedBack/>}/>
                        <Route path="/uptake_request/:id" element={<Uptake/>} />
                        <Route path="/uptake_request_by_venue/:id" element={<Uptake/>} />
                        <Route path="/get_feedback_report/:id" element={<FeedbackReport/>} />
                  </Routes>
                  <Footer/>
            </React.Fragment>
      );
}

export default App;
