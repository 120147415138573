import React,{ useState } from "react";

import { passwordChangeValidation } from "./../../Shared/UtilityChangePassword";
import { passwordChangeRequest } from "./../../Store/Auth/AuthActions"
import { useDispatch, useSelector } from "react-redux";
import { UiActions } from "../../Store/Ui/Ui";

const ChangePassword = (props) => {
    
      const [state,setState] = useState({
            current_password:{value:'',message:"",error:false},
            new_password:{value:'',message:"",error:false},
            confirm_new_password:{value:'',message:"",error:false}
      })

      const changePasswordRequest = useSelector(state => state.ui.changePasswordRequest)

      const dispatch = useDispatch();

      const changeStateHandler= (event)=>{

            const key = event.target.name;
            const val = event.target.value;
            
            setState(prevState=>{
                    return {
                            ...prevState,
                            [key]:{...prevState[key],value:val}
                    }
            })
      }

      const sumbitChangePasswordHandler = (event)=>{

            event.preventDefault();
            const updateArray = [];
            const coppyObject = {...state};
            for(let key in state){
                  
                  const res = passwordChangeValidation(state,key);
                  if(!res.isValid){
                        updateArray.push(res.validationMessage);
                  }

                  coppyObject[key] = {...coppyObject[key],error:!res.isValid,message:res.validationMessage}
            }

            setState(coppyObject)
            
            if(updateArray.length > 0){
                  // dispatch(UiActions.setAlert({model:true,message:updateArray[0]}));
                  return;
            }
           
            const token= localStorage.getItem('nhs_access_token');
            const data = {
                  current_password:state.current_password.value,
                  new_password:state.new_password.value,
                  confirm_new_password:state.confirm_new_password.value

            }

            dispatch(UiActions.setChangePasswordRequest({request:true}))
            dispatch(passwordChangeRequest({data:data,token:token}));

      }

      const clearErrorMessageHandler = ()=>{

            setState(prevState=>{
                  return {
                        ...prevState,
                        current_password:{...prevState.current_password,error:false,message:""},
                        new_password:{...prevState.new_password,error:false,message:""},
                        confirm_new_password:{...prevState.confirm_new_password,error:false,message:""},
                  }
            })
      }

      return (

            <form className={props.is_change_password == "true" && "is_change_password"}>
                  {/* <!-- Change Password --> */}
                  <div className="row row__title border-top">
                        <div className="col-md-12">
                              {props.is_change_password == "true" && <div className="heading-wrapper warning-text">
                                    <h3 className="heading fw-bold mb-0">
                                          Your account security has been enhanced! For your safety, we recommend updating your password to ensure your information remains secure.
                                    </h3>
                              </div>}
                              <div className="heading-wrapper">
                                    <h3 className="heading fw-bold mb-0">Change Password</h3>
                              </div>
                        </div>
                  </div>
                  {/* <!-- /.row --> */}

                  <div className="row">
                        <div className="col__left col-md-6">
                              <div className="form-group">
                                    <label htmlFor="changePasswordInputOldPassword" className="form-label fw-normal">Enter Old Password</label>
                                    <input type="password" className="form-control form-control-xl-lg" id="changePasswordInputOldPassword" name="current_password" value={state.current_password.value} onChange={changeStateHandler} onFocus={clearErrorMessageHandler} disabled={changePasswordRequest || props.nhs_vendor==='2'} autoComplete="off"/>
                                    {state.current_password.error &&
                                    <label className="form-label fw-normal" style={{"color":"var(--bs-red)","marginLeft":"15px"}}>{state.current_password.message}</label>}
                              </div>
                              {/* <!-- /.form-group --> */}
                              <div className="form-group">
                                    <label htmlFor="changePasswordInputNewPassword" className="form-label fw-normal">Enter New Password</label>
                                    <input type="password" className="form-control form-control-xl-lg" id="changePasswordInputNewPassword" name="new_password" value={state.new_password.value} onChange={changeStateHandler} onFocus={clearErrorMessageHandler} disabled={changePasswordRequest || props.nhs_vendor==='2'} autoComplete="off"/>
                                    {state.new_password.error &&
                                    <label className="form-label fw-normal" style={{"color":"var(--bs-red)","marginLeft":"15px"}}>{state.new_password.message}</label>}
                              </div>
                              {/* <!-- /.form-group --> */}
                              <div className="form-group">
                                    <label htmlFor="changePasswordInputConfirmNewPassword" className="form-label fw-normal">Re-enter New Password</label>
                                    <input type="password" className="form-control form-control-xl-lg" id="changePasswordInputConfirmNewPassword" name="confirm_new_password" value={state.confirm_new_password.value} onChange={changeStateHandler} onFocus={clearErrorMessageHandler} disabled={changePasswordRequest || props.nhs_vendor==='2'} autoComplete="off"/>
                                    {state.confirm_new_password.error &&
                                    <label className="form-label fw-normal" style={{"color":"var(--bs-red)","marginLeft":"15px"}}>{state.confirm_new_password.message}</label>}
                              </div>
                              {/* <!-- /.form-group --> */}
                        </div>
                        <div className="col__right col-md-6 d-md-flex align-items-md-end">
                              <div className="form-group form-group__buttons mt-lg-auto">
                                    <button type="submit" className="btn btn-xl-lg btn-primary  fw-bold" disabled={changePasswordRequest || props.nhs_vendor==='2'} onClick={sumbitChangePasswordHandler}>
                                          {!changePasswordRequest && "Submit"}
                                          {changePasswordRequest && "Please wait.."}
                                          {changePasswordRequest && <i style={{"marginLeft":"5px"}} className="fa fa-spinner fa-spin"></i>}
                                    </button>
                              </div>
                              {/* <!-- /.form-group --> */}
                        </div>
                  </div>
                  {/* <!-- /.row --> */}
            </form>

                                                            
      )
}

export default ChangePassword;
