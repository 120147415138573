import React,{ useState, useEffect} from 'react'

import { useNavigate, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";

import {checkValidityLogin} from "./../../Shared/UtilityLogin";
import { UiActions } from '../../Store/Ui/Ui';
import { AuthActions } from '../../Store/Auth/Auth';
import axios from 'axios';
import { BaseUrl } from '../../Shared/Constants';

const LoginModal = (props) => {

      const [loginMenu,setLoginMenu] = useState(false);
      const [forgotPassword,setForgotPassword] = useState(false);
      const ui = useSelector(state => state.ui);

      const [state , setState] = useState({
            email : {value:"",error:false,errorMessage:""},
            password : {value:"",error:false,errorMessage:""},
      });

      const dispatch = useDispatch();
      const navigate = useNavigate();
      const location = useLocation();
        
      useEffect(()=>{

            if(ui.joinRequest){
                  setForgotPassword(false);
            }

      },[ui.joinRequest])

      useEffect(()=>{
            if(location.state){

                  if(location.state.page === "login"){
                        // console.log(location.state)
                        setLoginMenu(true)
                  }
            }

      },[location.state])

      useEffect(()=>{
            
            if(location.pathname.includes("forgot_password")){
                  setLoginMenu(true)
                  setForgotPassword(true)
                  
            } else {

                  if(loginMenu){
                        setLoginMenu(false)
                        setForgotPassword(false)
                  }
            }

      },[location.pathname])
    
      useEffect(()=>{

            if(loginMenu ===false && props.hiddenMenu===false){

                  if(!location.pathname.includes("forgot_password")){
                        setForgotPassword(false)
                        
                  }
            }

            if(loginMenu ===false){
                  setState({
                        email : {value:"",error:"",errorMessage:""},
                        password : {value:"",error:"",errorMessage:""}
                  })
            }

      },[loginMenu,props.hiddenMenu])
     
      useEffect(()=>{

            if(ui.loginRequest === 'requested' || ui.forgotPasswordRequest === 'requested'){
                  setState({
                        email : {value:"",error:"",errorMessage:""},
                        password : {value:"",error:"",errorMessage:""}
                  })
                  dispatch(UiActions.setForgotPasswordRequest({request:false}));
                  dispatch(UiActions.setLoginRequest({request:false}));
            }
           
      },[ui.loginRequest,ui.forgotPasswordRequest])

      const handleChange = (e) => {

            const {name , value} = e.target   

            setState(prevState => ({
                        ...prevState,
                        [name] : {...prevState[name],value:value}
                  })
            )
      }

      const handleSubmitClick = (e) => 
      {

            e.preventDefault();
            
            let payload = null;

            const errorStore = [];
            const copy = {
            }

            for(let key in state)
            {

                  if(forgotPassword && key === "password"){
                        continue;
                  }
                  const errorCheck = checkValidityLogin(state[key].value,key);
                  if(!errorCheck.isValid)
                  {
                        errorStore.push(key);
                  }       
                  copy[key] = {...state[key],error:!errorCheck.isValid,errorMessage:errorCheck.validationMessage}
                        
            }

            if(forgotPassword){
                  payload={
                        "email":state.email.value,
                  }
            } else {

                  payload={
                        "email":state.email.value,
                        "password":state.password.value,
                  }
            }

            if(errorStore.length > 0){
                  setState({...copy})
            } 
            else {
                  if(forgotPassword){
                        dispatch(UiActions.setForgotPasswordRequest({request:true}));
                        sendForgotPasswordLinkRequest(payload)
                        // dispatch(sendForgotPasswordLinkRequest({data:payload,navigate:navigate}))
                  } else {
                        dispatch(UiActions.setLoginRequest({request:true}));
                        // dispatch(sendLoginRequest({data:payload,navigate:navigate}));
                        sendLoginRequest(payload);
                  }
            }
                
      }

      const onfocusHandler = ()=>{

            setState(prevState=>{

                  return {
                        ...prevState,
                        email:{...prevState.email,error:false,errorMessage:""},
                        password:{...prevState.password,error:false,errorMessage:""}
                  }
            })
      }

      const loginTogglerHandler = ()=>{
            setLoginMenu(!loginMenu)
      }

      const changeModuleHandler =(e)=>{
            e.preventDefault();
            setForgotPassword(!forgotPassword);
      }

      const sendLoginRequest =async (payload) =>{
            await axios.post(BaseUrl +'/auth/login',payload,{
                  headers:{
                        'reuqest_client': 'web',
                        'Content-Type': 'application/json'
                  }
            })
            .then(function (response) 
            {
                  // console.log(response.data,"sddd")
                  dispatch(AuthActions.reset());
                  dispatch(UiActions.reset())
                  const nhs_redirect = localStorage.getItem('nhs_redirect');
                  if(response.data.status === "1") {
                        if(response.data.data) {
                              dispatch(AuthActions.login({...response.data.data,"reactive":response.data.reactive || "0"}))
                              if(response.data?.data?.userInfo?.is_change_password == 'true'){
                                    console.log('here')
                                    navigate("settings",{ replace: true })
                                    return
                              }
                              if(nhs_redirect){
                                    if(response.data.data.userInfo.role  === "0" || response.data.data.userInfo.city === "0"){
                                          navigate("/currentListing",{ replace: true });
                                          localStorage.removeItem('nhs_redirect');
                                    }
                                    else{
                                          navigate(`${nhs_redirect}`);
                                          localStorage.removeItem('nhs_redirect');
                                    }
                              }
                              else{
                                    navigate("/currentListing",{ replace: true });
                              }
                        }
                  }
                  dispatch(UiActions.setLoginRequest({request:"requested"}))
            })
            .catch(function (error) {

                  // console.log(error.response)
                  dispatch(UiActions.setLoginRequest({request:false}))
                  if(error.response) {

                        if(error.response.status === 500)
                        {
                              if(error.response.data.status === "0"){

                                    // console.log(error.response.data.message)
                                    let type= "email";
                                    if(error.response.data.message.includes("password")){
                                          type = "password";
                                    }
                                    setState(prevState=>{
                                          return {
                                                ...prevState,
                                                [type]:{...prevState[type],error:true,errorMessage:error.response.data.message}
                                          }
                                    })
                              }
                              else{
                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.message || "Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                              }
                        }
                        else if(error.response.status === 404){
                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                        }
                  }
                  else {
                        dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}))
                  }
            });
      }

      const sendForgotPasswordLinkRequest =async (payload) =>{
            axios.post(BaseUrl + '/forgotPassword',payload,{
                  headers: {
                        // 'token': token,
                        'reuqest_client': 'web',
                        'Content-Type': 'application/json'
                  },
            })
            .then(function (response) 
            {
                  // console.log(response,"forgot password")
                  dispatch(UiActions.setAlert({model:true,message:response.data.message}));
                  dispatch(UiActions.setForgotPasswordRequest({request:'requested'}))
                  navigate("/")
                  setLoginMenu(false);
                    
            })
            .catch(function (error) {

                  // console.log(error.response)
                  dispatch(UiActions.setForgotPasswordRequest({request:false}))
                  if(error.response) {

                        if(error.response.status === 500)
                        {
                              if(error.response.data.status === "0"){
                                    setState(prevState=>{
                                          return {
                                                ...prevState,
                                                email:{...prevState.email,error:true,errorMessage:error.response.data.message}
                                          }
                                    })
                              } 
                              else {

                                    dispatch(UiActions.setAlert({model:true,message:error.response.data.message}));
                              }
                        }
                        else
                        {
                              dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}));
                        }
                  }
                  else {
                        dispatch(UiActions.setAlert({model:true,message:"Your device or internet connection's firewall or antivirus settings are blocking elements our website needs to function.  We suggest changing internet connections (such as a mobile phone) or adjusting your firewall settings."}));
                  }
            });
      }
    
      return (

            <li className="nav-item nav-item__login-button">
                  <button className="nav-link btn slideup__toggler d-flex justify-content-center d-none d-xl-block" type="button" aria-label="Toggle navigation" 
                  onClick={loginTogglerHandler}>
                        <span className="d-none d-xl-block">Login</span>
                  </button>
                  <div className={["slideup-dropdown slideup-login",loginMenu ? "show":""].join(" ")}>
                        <form autoComplete='off'>
                              <div className="form-group">
                                    <label htmlFor="loginInputEmail" className="form-label fw-normal">Email</label>
                                    <input type="email" name="email" className="form-control form-control-xl-md" id="loginInputEmail" value={state.email.value} required onChange={handleChange} disabled={ ((ui.loginRequest===true && !forgotPassword) || (ui.forgotPasswordRequest===true && forgotPassword)) ? true : false} onFocus={onfocusHandler}/>
                                    {state.email.error && <label className="form-label fw-normal" style={{"color":"var(--bs-red)","marginTop":"5px"}} dangerouslySetInnerHTML={{__html: state.email.errorMessage}}></label>}
                              </div>
                              {!forgotPassword &&
                              <div className="form-group">
                                    <label htmlFor="loginInputPassword" className="form-label fw-normal">Password</label>
                                    <input type="password" name="password" className="form-control form-control-xl-md" id="loginInputPassword" autoComplete="on" value={state.password.value} required onChange={handleChange} disabled={ ((ui.loginRequest===true && !forgotPassword) || (ui.forgotPasswordRequest===true && forgotPassword)) ? true : false} onFocus={onfocusHandler}/>
                                    {state.password.error && <label className="form-label fw-normal" style={{"color":"var(--bs-red)","marginTop":"5px"}} dangerouslySetInnerHTML={{__html: state.password.errorMessage}}></label>}
                              </div>}
                              <div className="form-group form-group__buttons">
                                    <button type="submit" className="btn btn-primary btn-xl-lg" disabled={ ((ui.loginRequest===true && !forgotPassword) || (ui.forgotPasswordRequest===true && forgotPassword)) ? true : false} onClick={handleSubmitClick}>
                                    {forgotPassword ? "Send Link" : "Submit"}
                                    </button>
                              </div>
                              <p className="form-label fw-normal mb-0">
                                    {!forgotPassword ? "Forgot the password? Click " : "Back to login " }<a href="/" onClick={changeModuleHandler}>here</a>
                              </p>
                        </form>
                  </div>
            </li>
      )
}

export default LoginModal
